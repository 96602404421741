import React, { useEffect, useRef } from "react"

import { gsap } from "gsap"
import { SplitText } from "gsap/SplitText"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(SplitText)
gsap.registerPlugin(ScrollTrigger)

const FadeInCharsEffect = ({
  children,
  tagName = "div",
  className,
  ...props
}) => {
  const TagName = tagName
  const refFadeInCharsEffect = useRef(null)

  useEffect(() => {
    let splitText = new SplitText(refFadeInCharsEffect.current, {
      type: "words, chars",
    })
    let split = splitText.chars

    gsap.from(split, {
      autoAlpha: 0,
      ease: "power1.out",
      stagger: 0.075,
      scrollTrigger: {
        scrub: 2,
        trigger: refFadeInCharsEffect.current,
        toggleActions: "play none none none",
        start: "start 100%",
        end: "bottom 90%",
      },
    })
  }, [])

  return (
    <TagName ref={refFadeInCharsEffect} className={className} {...props}>
      {children}
    </TagName>
  )
}

export default FadeInCharsEffect
