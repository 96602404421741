import React, { useEffect, useRef } from "react"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const FadeInBottomEffect = ({
  children,
  tagName = "div",
  className,
  distance = "100",
  easing = "power1.out",
  scrubbing = 2,
  opacity = "0",
  ...props
}) => {
  const TagName = tagName
  const refFadeInBottomEffect = useRef(null)

  useEffect(() => {
    gsap.from(refFadeInBottomEffect.current, {
      autoAlpha: opacity,
      y: distance,
      ease: easing,
      scrollTrigger: {
        scrub: scrubbing,
        trigger: refFadeInBottomEffect.current,
        toggleActions: "play none none none",
        start: "start 100%",
        end: "bottom 90%",
      },
    })
  }, [])

  return (
    <TagName ref={refFadeInBottomEffect} className={className} {...props}>
      {children}
    </TagName>
  )
}

export default FadeInBottomEffect
